@import '../../node_modules/bootstrap/scss/bootstrap';
$fa-font-path: '../../node_modules/font-awesome/fonts';
@import '../../node_modules/font-awesome/scss/font-awesome';
// @import '../node_modules/react-toastify/scss/font-awesome';
//$fa-font-path: "~fonts";
//@import 'node_modules/font-awesome/scss/font-awesome';

$glass-effect: blur(5px);

@mixin background-size($type) {
    -webkit-background-size: $type;
    -moz-background-size: $type;
    -o-background-size: $type;
    background-size: $type;
}

@mixin transition($type) {
    -webkit-transition: $type;
    -moz-transition: $type;
    -o-transition: $type;
    transition: $type;
}

@mixin keyframe($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }
    @-moz-keyframes #{$animation_name} {
        @content;
    }
    @-o-keyframes #{$animation_name} {
        @content;
    }
    @keyframes #{$animation_name} {
        @content;
    }
}

.table_row_link:hover {
    cursor: pointer;
}

:root {
    --html-background-image: none;
    --palatte-padding-left: 0px;
}

html {
    position: relative;
    height: 100%;
    @include transition(background-image 5s ease-in-out);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    @include background-size(cover);
}

body {
    margin-top: 3.5em;
    margin-bottom: 60px;
    background-color: inherit !important;
}

.modal-title {
    color: black;
    text-shadow: none;
}

.modal-body {
    color: black;
    text-shadow: none;
}

.modal-title.light {
    color: white;
}

.modal-body.light {
    color: white;
}

.clean-button {
    color: white;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
}

.clean-button:disabled {
    color: #999;
}

.glass {
    color: white;
    text-shadow: 0px 0px 3px #000000;
    border: 1px solid white;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 2px black;
    backdrop-filter: $glass-effect;
}

$glassColorTypes: (warning: $warning, danger: $danger, info: $info, success: $success);

@each $key, $val in $glassColorTypes {
    .glass.#{$key} {
        border-color: $val;
        background-color: rgba(shade-color($val, 80%),0.4);
    }
    .glass.#{$key} .glass-header {
        color: $val;
    }
}

@media (prefers-reduced-motion) {
    .glass {
        backdrop-filter: none;
        box-shadow: none;
        outline: 2px solid black;
        background-color: $dark;
    }

    @each $key, $val in $glassColorTypes {
        .glass.#{$key} {
            background-color: shade-color($val, 80%);
        }
    }
}

.glass-header {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.container > .glass {
    background-color: rgba(0, 0, 0, 0.5);
}

@media (prefers-reduced-motion) {
    .glass-header {
        background-color: $dark !important;
    }

    .container > .glass {
        background-color: $dark;
    }
}

.glass table {
    color: white;
    text-shadow: 0px 0px 3px #000000;
    --bs-table-bg: initial;
    --bs-table-color: initial;
}

.glass .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-color-type: initial;
}

.glass .btn {
    color: white;
}
.glass button {
    backdrop-filter: $glass-effect;
    border-width: 2px;
}

// Generate `.glass button.btn-*` color utilities
@each $color, $value in $theme-colors {
    .glass button.btn-#{$color} {
        background-color: rgba($value, 0.1);
    }
}

.glass .form-control {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: $glass-effect;
}
.glass .table-hover tr:hover {
    color: lightblue;
    background-color: rgba(0, 0, 0, 0.2);
}
.glass .form-control::placeholder {
    color: grey;
}
.glass .form-check-input {
    color: white;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: $glass-effect;
}
.glass .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
}
.glass .breadcrumb-item {
    color: white;
}

.glass A {
    color: lightblue;
}
/*
.glass {
    position: relative;
    color: white;
    text-shadow: 0px 0px 3px #000000;
    border: 1px solid white;
    //	padding: 0;
    //	padding: 1em;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 2px black;
    //	margin: 0;
    //	margin: 0.5em;
}

.glass table {
    color: white;
    text-shadow: 0px 0px 3px #000000;
}

.glass table.table-hover tr:hover {
    color: lightyellow;
}

.glass:before {
    content: '';
    background: inherit;
    @include transition(background-image 5s ease-in-out);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    @include background-size(cover);
    background-image: var(--html-background-image);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(15px);
    margin: -15px;
}

// glass alerts
.glass.alert-danger {
    @include glass-button($danger);
}

.glass.alert button:hover {
    color: white;
}

.glass a {
    color: white;
}
*/
// OpenLayers CSS
.ol-box {
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px solid blue;
}

.ol-mouse-position {
    top: 8px;
    right: 8px;
    position: absolute;
}

.ol-scale-line {
    background: rgba(0, 60, 136, 0.3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute;
}

.ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: none;
    color: #eee;
    font-size: 10px;
    text-align: center;
    margin: 1px;
    will-change: contents, width;
}

.ol-overlay-container {
    will-change: left, right, top, bottom;
}

.ol-unsupported {
    display: none;
}

.ol-viewport,
.ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ol-selectable {
    -webkit-touch-callout: default;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.ol-grabbing {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

.ol-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-control:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.ol-zoom {
    top: 0.5em;
    left: 0.5em;
}

.ol-rotate {
    top: 0.5em;
    right: 0.5em;
    transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
    top: 4.643em;
    left: 0.5em;
}

.ol-full-screen {
    right: 0.5em;
    top: 0.5em;
}

@media print {
    .ol-control {
        display: none;
    }
}

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: white;
    font-size: 1.14em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: 0.4em;
    background-color: rgba(0, 60, 136, 0.5);
    border: none;
    border-radius: 2px;
}

.ol-control button::-moz-focus-inner {
    border: none;
    padding: 0;
}

.ol-zoom-extent button {
    line-height: 1.4em;
}

.ol-compass {
    display: block;
    font-weight: normal;
    font-size: 1.2em;
    will-change: transform;
}

.ol-touch .ol-control button {
    font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
    top: 5.5em;
}

.ol-control button:hover,
.ol-control button:focus {
    text-decoration: none;
    background-color: rgba(0, 60, 136, 0.7);
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px;
}

.ol-attribution {
    text-align: right;
    bottom: 0.5em;
    right: 0.5em;
    max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
    margin: 0;
    padding: 0 0.5em;
    font-size: 0.7rem;
    line-height: 1.375em;
    color: #000;
    text-shadow: 0 0 2px #fff;
}

.ol-attribution li {
    display: inline;
    list-style: none;
    line-height: inherit;
}

.ol-attribution li:not(:last-child):after {
    content: ' ';
}

.ol-attribution img {
    max-height: 2em;
    max-width: inherit;
    vertical-align: middle;
}

.ol-attribution ul,
.ol-attribution button {
    display: inline-block;
}

.ol-attribution.ol-collapsed ul {
    display: none;
}

.ol-attribution.ol-logo-only ul {
    display: block;
}

.ol-attribution:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-attribution.ol-uncollapsible {
    bottom: 0;
    right: 0;
    border-radius: 4px 0 0;
    height: 1.1em;
    line-height: 1em;
}

.ol-attribution.ol-logo-only {
    background: transparent;
    bottom: 0.4em;
    height: 1.1em;
    line-height: 1em;
}

.ol-attribution.ol-uncollapsible img {
    margin-top: -0.2em;
    max-height: 1.6em;
}

.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
    display: none;
}

.ol-zoomslider {
    top: 4.5em;
    left: 0.5em;
    height: 200px;
}

.ol-zoomslider button {
    position: relative;
    height: 10px;
}

.ol-touch .ol-zoomslider {
    top: 5.5em;
}

.ol-overviewmap {
    left: 0.5em;
    bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
    bottom: 0;
    left: 0;
    border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
    display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #7b98bc;
    height: 150px;
    margin: 2px;
    width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 1px;
    left: 2px;
    position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
    display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
    border: 2px dotted rgba(0, 60, 136, 0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
    cursor: move;
}

@keyframes fa-blink {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.fa-blink {
    -webkit-animation: fa-blink 1s linear infinite;
    -moz-animation: fa-blink 1s linear infinite;
    -ms-animation: fa-blink 1s linear infinite;
    -o-animation: fa-blink 1s linear infinite;
    animation: fa-blink 1s linear infinite;
}